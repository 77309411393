<template>
  <b-card no-body>
    <b-card-header>
      <!-- <b-card-title>Latest Statistics</b-card-title> -->
      <!-- datepicker -->
      <!-- <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div> -->
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body v-if="dadosCobranca">
      <chartjs-component-bar-chart
        :height="400"
        :data="latestBarChart.data"
        :options="latestBarChart.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody,
} from 'bootstrap-vue'
// import flatPickr from 'vue-flatpickr-component'
import { $themeColors } from '@themeConfig'
import ChartjsComponentBarChart from './charts-components/ChartjsComponentBarChart.vue'
import api from '../../../../../axios-auth'

const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    // BCardTitle,
    // flatPickr,
    ChartjsComponentBarChart,
  },
  props: {
    credores: {
      type: Array,
      default: () => [],
    },
    credoresfiltro: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ano: [],
      total: [],
      dadosCobranca: false,
      somaCobranca: 0,
      latestBarChart: {
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: chartColors.successColorShade,
              borderColor: 'transparent',
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'bottom',
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  stepSize: 100,
                  min: 0,
                  max: 0,
                  fontColor: chartColors.labelColor,
                },
              },
            ],
          },
        },
      },
    }
  },
  created() {
    this.getGraficoCobranca()
  },
  methods: {
    getGraficoCobranca() {
      let body
      const idDefault = []
      this.credores.map(id => {
        idDefault.push(id.id)
      })
      const credIds = []
      this.credoresfiltro.map(id => {
        credIds.push(id.id)
      })
      if (this.credoresfiltro.length) {
        body = {
          credores: credIds,
        }
      } else {
        body = {
          credores: idDefault,
        }
      }
      api.post('api/v1/credores/totalizadores/base/cobranca', body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        const dt = res.data.dados
        this.dadosCobranca = true
        // GRAFICO COBRANÇA
        dt.map(item => {
          this.total.push(parseFloat(item.total).toFixed(2))
        })

        this.latestBarChart.data.datasets[0].data = this.total

        dt.map(item => {
          this.ano.push(item.ano)
        })
        dt.map(soma => {
          this.somaCobranca += parseFloat(soma.total)
        })

        const uniqueAnos = [...new Set(this.ano)]
        this.latestBarChart.data.labels = uniqueAnos.sort((a, b) => a.localeCompare(b))

        const maxNumber = Math.max(...this.total)
        const newMaxNumber = maxNumber * 1.1

        this.latestBarChart.options.scales.yAxes[0].ticks.max = newMaxNumber
        this.$emit('somaCobranca', this.somaCobranca)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
