<template>
  <div>
    <b-row>
      <b-col>
        <TotalizadorDashCoordenador
          ref="totais"
          :get-pendencias="getPendencias"
          :select-card="loadCard"
          :contadores="contadores"
          :update-propostas="updatePropostas"
          :update-acordos="countAcordos"
          :update-baixas="countBaixas"
          :update-lista="listarRetiradaNegativacao"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="card === 8">
        <b-card
          title="Baixa Administrativa"
        >
          <BaixaAdminstrativa
            :itemsbaixa="itemsBaixas"
          />
        </b-card>
      </b-col>
      <b-col v-if="card === 9">
        <b-card
          title="Retirar Negativação"
        >
          <RetirarNegativacao
            :itemsretirar="itemsRetiradaNegativacao"
          />
        </b-card>
      </b-col>
      <b-col v-if="card === 10">
        <b-card
          title="Percentual"
        >
          <Percentual />
        </b-card>
      </b-col>
      <b-col v-if="card === 11">
        <b-card title="Boletos Liquidados">
          <BoletosLiquidados />
        </b-card>
      </b-col>
      <b-row>
        <b-col v-if="card === 0">
          <b-card
            title="Acordos"
          >
            <Acordos />
          </b-card>
        </b-col>
        <b-col v-if="card === 1">
          <b-card
            title="Propostas"
          >
            <PropostaAcordo
              :update-contador="countPropostas"
            />
          </b-card>
        </b-col>
        <b-col v-if="card === 2">
          <!-- <b-overlay
          :show="load"
          variant="transparent"
        > -->
          <b-card
            title="Operações com Pendência"
          >
            <TabelaPendencia
              v-if="card === 2"
              :items="itemsPendencias"
              :update-table="getPendencias"
            />
          </b-card>
        <!-- </b-overlay> -->
        </b-col>
        <b-col v-if="card === 5">
          <b-card title="Classificação clientes">
            <ClassificacaoCliente
              :items="itemsClients"
            />
          </b-card>
        </b-col>

        <b-col v-if="card === 6">
          <b-card title="Lista de anexos">
            <AnexosList
              :items="anexosList"
              :update-table="getAnexos"
            />
          </b-card>
        </b-col>

        <b-col v-if="card === 4">
          <b-card
            title="Carteira"
          >
            <carteira />
          </b-card>
        </b-col>
        <b-col v-if="card === 7">
          <b-card
            title="Troca de Executivos"
          >
            <TrocaExecutivos
              :items="trocaExecutivos"
              :update-table="getTrocas"
            />
          </b-card>
        </b-col>
      </b-row>

    </b-row></div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import axios from '@/../axios-auth'
import TotalizadorDashCoordenador from './backoffice/TotalizadorDashCoordenador.vue'
import BaixaAdminstrativa from '../financeiro/dashBoard/components/BaixaAdm.vue'
import RetirarNegativacao from '../financeiro/dashBoard/components/RetirarNegativacao.vue'
import Acordos from '../cobranca/acordos/AcordosHome.vue'
import Carteira from '../cobranca/supervisor/components/Carteira.vue'
import TabelaPendencia from '../cobranca/supervisor/components/Pendencias.vue'
import ClassificacaoCliente from '../cobranca/supervisor/components/ClassificacaoCliente.vue'
import AnexosList from '../cobranca/supervisor/components/AnexosList.vue'
import PropostaAcordo from '../cobranca/supervisor/components/PoropostaAcrodo.vue'
import TrocaExecutivos from '../cobranca/supervisor/components/TrocaExecutivos.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BaixaAdminstrativa,
    RetirarNegativacao,
    TabelaPendencia,
    ClassificacaoCliente,
    AnexosList,
    Carteira,
    PropostaAcordo,
    Acordos,
    TrocaExecutivos,
    TotalizadorDashCoordenador,
  },
  data() {
    return {
      itemsBaixas: [],
      itemsRetiradaNegativacao: [],
      dueDate: {
        start: '',
        end: '',
      },
      card: '',
      countClientDataStatus: [],
      itemsPendencias: [],
      itemsClients: [],
      anexosList: [],
      load: true,
      propostas: '',
      trocaExecutivos: [],
    }
  },
  async created() {
    await this.getPendencias()
    await this.getClassificationClient()
    await this.countPropostas()
    await this.countAcordos()
    await this.countBaixas()
    await this.getAnexos()
    await this.getTrocas()
    await this.listarRetiradaNegativacao()
  },
  methods: {
    async countAcordos() {
      const data = new Date()
      let dia = String(data.getDate()).padStart(2, '0')
      const mes = String(data.getMonth() + 1).padStart(2, '0')
      const ano = data.getFullYear()

      if (mes === '01' || mes === '03' || mes === '05' || mes === '07' || mes === '08' || mes === '10' || mes === '12') {
        dia = '31'
      } else if (mes === '04' || mes === '06' || mes === '09' || mes === '11') {
        dia = '30'
      } else if ((ano % 4 === 0 && ano % 100 !== 0) || ano % 400 === 0) {
        dia = '29'
      } else {
        dia = '28'
      }

      const filtro = {
        data_inicial: ` ${ano}-${mes}-01`.concat(' 00:00:00'),
        data_final: ` ${ano}-${mes}-${dia}`.concat(' 23:59:59'),
      }

      await axios.post('api/v1/acordo/filtroAcordo', { filtros: filtro }, {
        headers: {
          Accept: 'Application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(res => {
        const totalBaixa = []
        res.data.dados.filtro.map(item => {
          if (item.status === 'Baixa Administrativa') {
            totalBaixa.push(item)
          }
        })
        this.itemsBaixas = totalBaixa
        this.$refs.totais.updateAcordos(totalBaixa.length)
      })
    },
    async getTrocas() {
      this.trocaExecutivos = []
      axios.get('api/v1/troca_executivo/listar', {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      })
        .then(res => {
          res.data.dados.map(item => {
            if (item.status === 'Analise') {
              this.trocaExecutivos.unshift(item)
            }
          })
        })
    },
    async countBaixas() {
      const data = new Date()
      let dia = String(data.getDate()).padStart(2, '0')
      const mes = String(data.getMonth() + 1).padStart(2, '0')
      const ano = data.getFullYear()

      if (mes === '01' || mes === '03' || mes === '05' || mes === '07' || mes === '08' || mes === '10' || mes === '12') {
        dia = '31'
      } else if (mes === '04' || mes === '06' || mes === '09' || mes === '11') {
        dia = '30'
      } else if ((ano % 4 === 0 && ano % 100 !== 0) || ano % 400 === 0) {
        dia = '29'
      } else {
        dia = '28'
      }

      const filtro = {
        data_inicial: ` ${ano}-${mes}-01`.concat(' 00:00:00'),
        data_final: ` ${ano}-${mes}-${dia}`.concat(' 23:59:59'),
      }

      await axios.post('api/v1/acordo/filtroAcordo', { filtros: filtro }, {
        headers: {
          Accept: 'Application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(res => {
        const totalBaixa = []
        res.data.dados.filtro.map(item => {
          if (item.status === 'Baixa Administrativa') {
            totalBaixa.push(item)
          }
        })
        this.itemsBaixas = totalBaixa
        this.$refs.totais.updateBaixas(totalBaixa.length)
      })
    },

    loadCard(data) {
      if (this.card === data) {
        this.card = null
      }
      this.card = data
    },

    async getPendencias() {
      this.load = true
      this.pendecias = []
      await axios.get('api/v1/operacoes/pendencias', {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      }).then(async res => {
        this.itemsPendencias = res.data.dados
      })

      this.totalRows = this.itemsPendencias.length
      this.$refs.totais.updateContadores(this.itemsPendencias.length)
      this.load = false
    },

    async listarRetiradaNegativacao() {
      await axios.get('api/v1/operacoes/listar/retirar/negativacao', {
        headers: {
          Accept: 'Application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      })
        .then(res => {
          const totalListaRetiradaNegativacao = []
          res.data.dados.map(item => {
            totalListaRetiradaNegativacao.push(item)
          })
          this.itemsRetiradaNegativacao = totalListaRetiradaNegativacao
          this.$refs.totais.updateLista(totalListaRetiradaNegativacao.length)
        })
    },
    countPropostas() {
      axios.get('api/v1/proposta/listar/', {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          Accept: 'application/json',
        },
      }).then(async res => {
        const count = []
        await res.data.dados.map(dt => {
          if (dt.status === 'Analise Supervisao') {
            count.push(dt)
          }
        })
        await this.$refs.totais.updatePropostas(count.length)
      })
    },

    async getClassificationClient() {
      await axios.get('api/v1/relatorios/backoffice/listar/statusClientes', {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          Accept: 'application/json',
        },
      }).then(res => {
        this.itemsClients = res.data.dados
      })
    },

    async getAnexos() {
      await axios.get('api/v1/anexos/listar').then(res => {
        this.anexosList = res.data
      })
    },

  },
}
</script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
