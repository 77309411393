<template>
  <div>
    <b-row>
      <b-col>
        <cards
          ref="totais"
          :select-card="loadCard"
          :contadores="contadores"
          :update-propostas="updatePropostas"
          :update-acordos="countAcordos"
          :update-lista="listarRetiradaNegativacao"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="card === 0">
        <b-card
          title="Baixa Administrativa"
        >
          <BaixaAdminstrativa
            :itemsbaixa="itemsBaixas"
          />
        </b-card>
      </b-col>
      <b-col v-if="card === 1">
        <b-card
          title="Retirar Negativação"
        >
          <RetirarNegativacao
            :itemsretirar="itemsRetiradaNegativacao"
          />
        </b-card>
      </b-col>
      <b-col v-if="card === 2">
        <b-card
          title="Percentual"
        >
          <Percentual />
        </b-card>
      </b-col>
      <b-col v-if="card === 3">
        <b-card title="Boletos Liquidados">
          <BoletosLiquidados />
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import axios from '@/../axios-auth'
import Cards from './components/Cards.vue'
import BaixaAdminstrativa from './components/BaixaAdm.vue'
import RetirarNegativacao from './components/RetirarNegativacao.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    Cards,
    BaixaAdminstrativa,
    RetirarNegativacao,
  },
  data() {
    return {
      itemsBaixas: [],
      itemsRetiradaNegativacao: [],
      dueDate: {
        start: '',
        end: '',
      },
      card: '',
    }
  },
  created() {
    this.countAcordos()
    this.listarRetiradaNegativacao()
  },
  methods: {
    async countAcordos() {
      await axios.get('api/v1/acordo/baixa_admin', {
        headers: {
          Accept: 'Application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(res => {
        const totalBaixa = []
        res.data.dados.map(item => {
          totalBaixa.push(item)
        })
        this.itemsBaixas = totalBaixa
        this.$refs.totais.updateAcordos(totalBaixa.length)
      })
    },

    loadCard(data) {
      if (this.card === data) {
        this.card = null
      }
      this.card = data
    },

    async listarRetiradaNegativacao() {
      await axios.get('api/v1/operacoes/listar/retirar/negativacao', {
        headers: {
          Accept: 'Application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      })
        .then(res => {
          const devolucoesExcluidas = [22]
          const totalListaRetiradaNegativacao = []
          res.data.dados.map(item => {
            if (!devolucoesExcluidas.includes(item.motivo_devolucao_id)) {
              totalListaRetiradaNegativacao.push(item)
            }
          })
          this.itemsRetiradaNegativacao = totalListaRetiradaNegativacao
          this.$refs.totais.updateLista(totalListaRetiradaNegativacao.length)
        })
    },

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
