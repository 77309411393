<template>
  <div>
    <b-col>
      <b-card hidden>
        <DashMigracao />
      </b-card>
    </b-col>
    <b-card
      v-if="items"
      no-body
      class="card-statistics"
    >
      <b-card-header>
        <b-card-title>Estatísticas</b-card-title>
        <b-card-text class="font-small-2 mr-25 mb-0">
          <!-- Ultima atualização: {{ hoje }} -->
        </b-card-text>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="(item, i) in items"
            :key="item.icon"
            xl="3"
            sm="6"
            :class="item.customClass"
            @click="itemClick(i)"
          >
            <b-media
              no-body
              class="cursor-pointer"
            >
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                  <b-overlay
                    :show="item.load"
                    class="md-1"
                    spinner-variant="dark"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-body>
        <span class="font-weight-bolder mb-0">Atalhos Financeiros</span>
        <b-row>
          <b-col
            v-for="(item, i) in atalhosFinanceiro"
            :key="i"
            xl="3"
            sm="6"
            class="cursor-pointer"
            @click="clickAtalho(i)"
          >
            <b-media-body class="my-auto">
              <h4 class="mt-1 font-small-3 mb-0">
                {{ item.title }}
                <feather-icon
                  size="16"
                  :icon="item.icon"
                />
              </h4>
            </b-media-body>
          </b-col></b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BOverlay,
} from 'bootstrap-vue'
import DashMigracao from '../../../cobranca/supervisor/components/DashMigracao.vue'
// import axios from '@/../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BOverlay,
    DashMigracao,
  },
  props: {
    selectCard: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      items: [
        {
          load: true,
          color: 'light-primary',
          customClass: 'mt-1 mb-2 mb-xl-0',
          icon: 'ClipboardIcon',
          subtitle: 'Baixas Administrativas',
          title: '',
        },
        {
          load: false,
          color: 'light-danger',
          customClass: 'mt-1 mb-2 mb-xl-0',
          icon: 'UserMinusIcon',
          subtitle: 'Retirar Negativação',
          title: '',
        },
        {
          load: false,
          color: 'light-success',
          customClass: 'mt-1 mb-2 mb-xl-0',
          icon: 'PercentIcon',
          subtitle: 'Percentual',
          title: '',
        },
        {
          color: 'light-warning',
          customClass: 'mt-1 mb-2 mb-xl-0',
          icon: 'AwardIcon',
          subtitle: 'Boletos Liquidados',
          title: '',
        },
      ],
      atalhosFinanceiro: [
        {
          icon: 'NavigationIcon',
          title: 'BAIXAS DE BOLETOS',
        },
        {
          icon: 'NavigationIcon',
          title: 'RECEBIMENTOS',
        },
        {
          icon: 'NavigationIcon',
          title: 'CLIENTES NEGATIVADOS',
        },
      ],
    }
  },
  created() {
  },
  methods: {
    updateContadores(value) {
      this.items[2].load = false
      this.items[2].title = value
    },
    updateAcordos(value) {
      this.items[0].load = false
      this.items[0].title = value
    },
    updateLista(value) {
      this.items[1].load = false
      this.items[1].title = value
    },

    async itemClick(data) {
      if (data === 0) {
        await this.selectCard(0)
      } else if (data === 1) {
        await this.selectCard(1)
      } else if (data === 2) {
        await this.selectCard(2)
      } else if (data === 3) {
        await this.selectCard(3)
      }
    },
    async clickAtalho(data) {
      if (data === 0) {
        this.$router.push({ name: 'financeiro.boletos' })
      } else if (data === 1) {
        this.$router.push({ name: 'financeiro.prestacao' })
      } else if (data === 2) {
        this.$router.push({ name: 'financeiro.negativacaocliente' })
      }
    },
  },
}
</script>
