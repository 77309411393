<template>
  <div>
    <b-row>
      <b-col>
        Remover Negativação
        <v-select
          v-model="statusNegativacao"
          :options="mtNegat.options"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import axios from '../../../../../../axios-auth'

export default {
  components: {
    vSelect,
  },
  props: {
    operacoes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      mtNegat: {
        options: [],
        id: [],
        selected: '',
      },
      statusNegativacao: null,
      operacoesIds: [],
      refreshGrid: true,
    }
  },
  async created() {
    // LISTAGEM DE MOTIVO NEGATIVAÇÃO
    await axios.get('api/v1/classificacao_negativacao', {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
      },
    })
      .then(res => {
        // eslint-disable-next-line array-callback-return
        res.data.dados.dados.data.map(item => {
          if (item.id !== 1) {
            this.mtNegat.options.push({
              label: item.nome,
              id: item.id,
            })
          }
        })
      })
    this.operacoes.map(item => {
      this.operacoesIds.push(item.id)
    })
  },
  methods: {
    clearSelect() {
      this.operacoesIds = []
    },
    updateNegativacao() {
      if (this.statusNegativacao && this.operacoesIds.length > 0) {
        const dataDev = {
          classificacao_negativacao_id: this.statusNegativacao.id,
          ops_ids: this.operacoesIds,
          description: this.statusNegativacao.label,
          motivoId: 51,
        }
        const headers = {
          Accept: 'Application/json',
          headers: { Authorization: 'Bearer '.concat(localStorage.getItem('token')) },
        }

        axios.put('api/v1/operacoes/update/lote/negativado/operacao/', dataDev, headers).then(res => {
          if (res.status === 200) {
            this.$swal({
              title: 'sucesso',
              text: 'Alteração feita com sucesso',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
      } else {
        this.$swal({
          title: 'Error!',
          text: 'Erro ao fazer a alteração!',
          icon: 'error',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
      this.clearSelect()
      this.$emit('refreshGrid', this.refreshGrid)
    },
  },
}
</script>
