<template>
  <div>
    <cobranca-dash
      v-if="dash === 'cobranca'"
    />
    <credor-dash
      v-if="dash === 'credor'"
    />
    <DashMigracao
      v-if="dash === 'usuarios'"
      hidden
    />
    <DashboardCoordenador
      v-if="dash === 'coordenador'"
    />
    <FinanceiroDash
      v-if="dash === 'financeiro'"
    />
  </div>
</template>
<script>
// import BackofficeDash from '@/views/backoffice/dashboard/BackofficeDash.vue'

// imports do text-area customizado

import CobrancaDash from '@/views/cobranca/dashboard/DashboardCobranca.vue'
import CredorDash from '@/views/credor/Dashboard/DashCredor.vue'
import DashMigracao from '../cobranca/supervisor/components/DashMigracao.vue'
import FinanceiroDash from '../financeiro/dashBoard/DashFinanceiro.vue'
import DashboardCoordenador from './DashboardCoordenador.vue'

export default {
  components: {
    // BackofficeDash,
    CobrancaDash,
    CredorDash,
    DashMigracao,
    FinanceiroDash,
    DashboardCoordenador,
  },
  data() {
    return {
      dash: '',
    }
  },
  async created() {
    await this.selectDash()
  },
  methods: {
    selectDash() {
      const funcao = localStorage.getItem('funcao')
      if (localStorage.getItem('credores')) {
        this.dash = 'credor'
      } else if (funcao === 'Executivo de Cobrança' || funcao === 'Supervisor(a) Cobrança') {
        this.dash = 'cobranca'
      } else if (funcao !== 'Supervisor(a) Cobrança' && !this.isFinanceiro() && funcao !== 'Coordenador(a) Geral') {
        this.dash = 'usuarios'
      } else if (this.isFinanceiro()) {
        this.dash = 'financeiro'
      } else if (funcao === 'Coordenador(a) Geral') {
        this.dash = 'coordenador'
      }
    },
  },
}
</script>
